import { Icon, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'gatsby'
import React from 'react'
import Alllinks from './alllinks'
import './layout.css'

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
  },
}))

const ActivitiesLinks = props => {
  const classes = useStyles()
  return (
    <div>
      <Typography variant="h5" className={classes.titleFont}>
        In This Section
      </Typography>
      <List>
        {Alllinks.activities.map((v, k) => (
          <ListItem button key={k} component={Link} to={v.link}>
           <Icon style={{ marginRight: 24, color: '#1B3263' }}>{v.icon}</Icon>
            <ListItemText
              primary={v.name}
              style={{ color: '#1B3263' }}
              className={classes.titleFont}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default ActivitiesLinks
