import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/PlayCircleOutline'
import { Link } from 'gatsby'
import './layout.css'
import { makeStyles } from '@material-ui/styles'
const data = [
  {
    name: 'General',
    link: '/resources',
  },
  {
    name: 'Income tax Guest House',
    link: '/resources/income-tax-guest-house',
  },
  {
    name: 'Hotel Tie ups',
    link: '/resources/hotel-tie-ups',
  },
  {
    name: 'Downloadable Logos',
    link: '/resources/downloadable-logos',
  },
  {
    name: 'Downloadable Civil List',
    link: '/resources/downloadable-civil-list',
  },
  {
    name: 'Leave Matters',
    link: '/resources/leave-matters',
  },
  {
    name: 'Links for some useful books/ebooks',
    link: '/resources/useful-ebooks',
  },
  {
    name: 'Suggested Readings',
    link: '/aboutus/suggested-readings',
  },
]

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
  },
}))

const ResourcesLinks = props => {
  const classes = useStyles()
  return (
    <div>
      <Typography variant="h5" className={classes.titleFont}>
        In This Section
      </Typography>
      <List>
        {data.map((v, k) => (
          <ListItem button key={k} component={Link} to={v.link}>
            <ListItemIcon>
              <LinkIcon style={{ color: '#1B3263' }} />
            </ListItemIcon>
            <ListItemText
              primary={v.name}
              style={{ color: '#1B3263' }}
              className={classes.titleFont}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default ResourcesLinks
