import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import AboutUsLinks from './aboutuslinks'
import ActivitiesLinks from './activitieslinks'
import CitizenLinks from './citizenlinks'
import {
  ABOUTUS,
  ACTIVITIES,
  CITIZEN_CORNER,
  IRS_CORNER,
  PUBLICATIONS,
  RESOURCES
} from './constant'
import IRSConerLinks from './irsconerlinks'
import './layout.css'
import NewHeader from './NewHeader'
import PublicationLinks from './publicationliks'
import ResourcesLinks from './resourceslinks'
const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  marginMainTop: {
    marginTop: -150,
    marginBottom: 84,
  },
  marginIPADTop: {
    marginTop: -200,
    marginBottom: 84,
  },
  mt24: {
    marginTop: 24,
  },
  white: {
    color: '#fff',
  },
  paddingAll24: {
    padding: 18,
  },
}))
const PageStructure = props => {
  function customRender(mode) {
    switch (mode) {
      case ABOUTUS: {
        return <AboutUsLinks />
      }
      case ACTIVITIES: {
        return <ActivitiesLinks />
      }
      case PUBLICATIONS: {
        return <PublicationLinks />
      }
      case CITIZEN_CORNER: {
        return <CitizenLinks />
      }
      case IRS_CORNER: {
        return <IRSConerLinks />
      }
      case RESOURCES: {
        return <ResourcesLinks />
      }
    }
  }
  const classes = useStyles()
  const matches = useMediaQuery('(max-width:1024px)')
  return (
    <div>
      <NewHeader title="About us" image={props.image} />
      {matches ? (
        <div className={classes.marginIPADTop}>
          <Container>
            <Typography className={clsx(classes.titleFont, classes.white)} align="center">
              {props.title}
            </Typography>
            <Grid container spacing={2} className={classes.mt24}>
              <Hidden xsDown mdDown>
                <Grid item md={12} xs={12} sm={12}>
                  <Paper square>
                    <div
                      style={{ backgroundColor: '#3c5eab', padding: 6 }}
                    ></div>
                    <div className={classes.paddingAll24}>
                      <Typography
                        variant="body1"
                        className={classes.mt24}
                        align="justify"
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: props.content }}
                        ></div>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              </Hidden>
              <Grid item md={12} xs={12} sm={12}>
                <Paper square>
                  <div style={{ backgroundColor: '#3c5eab', padding: 6 }}></div>
                  <div className={classes.paddingAll24}>
                    <Typography
                      variant="body1"
                      className={classes.mt24}
                      align="justify"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: props.content }}
                      ></div>
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Hidden xsDown mdDown>
                <Grid item md={3} xs={12}>
                  <Paper square>
                    <div
                      style={{ backgroundColor: '#b12229', padding: 6 }}
                    ></div>
                    <div className={classes.paddingAll24}>
                      {customRender(props.mode)}
                    </div>
                  </Paper>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </div>
      ) : (
          ''
        )
      }
      <div className={classes.marginMainTop}>
        <Container>
          <Typography className={clsx(classes.titleFont, classes.white)}>
            {props.title}
          </Typography>
          <Grid container spacing={2} className={classes.mt24}>
            {/* <Hidden xsDown mdDown>
              <Grid item md={9} xs={12} sm={12} >
                <Paper square>
                  <div style={{ backgroundColor: '#3c5eab', padding: 6 }}></div>
                  <div className={classes.paddingAll24}>
                    <Typography
                      variant="body1"
                      className={classes.mt24}
                      align="justify"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: props.content }}
                      ></div>
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Hidden> */}
            {matches ? (
              ''
            ) : (
                <Grid item md={12} xs={12} sm={12}>
                  <Paper square>
                    {/* <div style={{ backgroundColor: '#3c5eab', padding: 6 }}></div> */}
                    <div className={classes.paddingAll24}>
                      <Typography
                        variant="body1"
                        className={classes.mt24}
                        align="justify"
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: props.content }}
                        ></div>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              )}

            {/* <Hidden xsDown mdDown>
              <Grid item md={3} xs={12}>
                <Paper square>
                  <div style={{ backgroundColor: '#b12229', padding: 6 }}></div>
                  <div className={classes.paddingAll24}>
                    {customRender(props.mode)}
                  </div>
                </Paper>
              </Grid>
            </Hidden> */}
          </Grid>
        </Container>
      </div>
    </div >
  )
}

export default PageStructure
