import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const Programs = () => {
  return (
    <div>
      <Layout>
        <SEO title="Programs" />
        <PageStructure title="Programs" desc="TESTIG" mode={ABOUTUS} />
      </Layout>
    </div>
  )
}

export default Programs
