import { makeStyles } from '@material-ui/core'
import React from 'react'
import './layout.css'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  shiv: {
    height: '60vh',
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    backgroundColor: 'rgba(200,100,0,.5)'
  },

  centerTitle: {
    fontFamily: 'Volkhov',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '60vh',
    color: '#FFF',
    fontSize: 44,
  },

  subtitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '60vh',
    color: '#FFF',
    fontSize: 24,
  },

  overlay: {
    zIndex: 1,
    height: '100%',
    width: '100%',
    position: 'fixed',
    overflow: 'auto',
    top: '0px',
    left: '0px',
    background: 'rgba(0, 0, 0, 0.7)'
  }
}))
const NewHeader = props => {
  const classes = useStyles()
  return (
    <div>
      <div
        className={classes.shiv}
        style={{
          backgroundImage: 'url(' + props.image + ')',

        }}
      >
        {/* <div className={clsx(classes.centerTitle)}>{props.title}</div> */}
      </div>
    </div>
  )
}

export default NewHeader
