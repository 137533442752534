import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Icon,
} from '@material-ui/core'
import Alllinks from './alllinks'
import LinkIcon from '@material-ui/icons/PlayCircleOutline'
import { Link } from 'gatsby'
import './layout.css'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
  },
}))

const AboutUsLinks = props => {
  const classes = useStyles()
  return (
    <div>
      <Typography variant="h5" className={classes.titleFont}>
        In This Section
      </Typography>
      <List>
        {Alllinks.irscorner.map((v, k) => (
          <ListItem button key={k} component={Link} to={v.link}>
            <Icon style={{ marginRight: 24, color: '#1B3263' }}>{v.icon}</Icon>
            <ListItemText
              primary={v.name}
              style={{ color: '#1B3263' }}
              className={classes.titleFont}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default AboutUsLinks
