const data = {
  aboutus: [
    {
      name: 'General',
      link: '/aboutus',
      icon: 'check_circle_outline',
    },
    {
      name: 'Structure',
      link: '/aboutus/structure',
      icon: 'account_tree',
    },
    {
      name: 'Objective',
      link: '/aboutus/objective',
      icon: 'filter_center_focus',
    },
    {
      name: 'IRSA President Desk',
      link: '/aboutus/presedentdesk',
      icon: 'security',
    },
    // {
    //   name: 'All India executive committee',
    //   link: '/aboutus/allindiaexecutivecommittee',
    //   icon: 'people',
    // },
    {
      name: 'IRSA Executive Committee',
      link: '/aboutus/executivecommittee',
      icon: 'people_outline',
    },
    {
      name: 'Local chapters',
      link: '/aboutus/localchapter',
      icon: 'location_on',
    },
    {
      name: 'IRSA bylaws',
      link: '/aboutus/irsabylaws',
      icon: 'beenhere',
    },
    {
      name: 'IRSA elections',
      link: '/aboutus/irsaelections',
      icon: 'double_arrow',
    },
    {
      name: 'Programs',
      link: '/aboutus/programs',
      icon: 'video_library',
    },
    {
      name: 'Events and Updates',
      link: '/aboutus/eventsandupdates',
      icon: 'event_available',
    },
    {
      name: 'Get Involved',
      link: '/aboutus/getinvolved',
      icon: 'add_comment',
    },
    {
      name: 'List of IRSA President',
      link: '/aboutus/list-of-irsa-president',
      icon: 'sort_by_alpha',
    },
    {
      name: 'Contact Us',
      link: '/contactus',
      icon: 'call',
    },
  ],
  activities: [
    {
      name: 'General',
      link: '/activities',
      icon: 'check_circle_outline',
    },
    {
      name: 'CSR Activity',
      link: '/activities/csractivity',
      icon: 'spa',
    },
    {
      name: 'Outreach Program',
      link: '/activities/outreachprogram',
      icon: 'language',
    },
    {
      name: 'IRS farewell',
      link: '/activities/irsfarewell',
      icon: 'school',
    },
  ],
  irscorner: [
    {
      name: 'General',
      link: '/irscorner',
      icon: 'check_circle_outline',
    },
    {
      name: 'Officers on Deputation',
      link: '/irscorner/officers-on-deputation',
      icon: 'policy',
    },
    {
      name: 'Deputation Opportunities',
      link: '/irscorner/deputation-opportunities',
      icon: 'assignment_turned_in',
    },
    {
      name: 'Important Notification',
      link: '/irscorner/important-notification',
      icon: 'notifications_active',
    },
    {
      name: 'Empanelment Updates',
      link: '/irscorner/empanelment-updates',
      icon: 'error_outline',
    },
    {
      name: 'Resources',
      link: '/resources',
      icon: 'build',
    },
    {
      name: 'Training Opportunities',
      link: '/irscorner/training-opportunities',
      icon: 'business_center',
    },
    {
      name: 'Higher Education',
      link: '/irscorner/higher-education',
      icon: 'school',
    },
    {
      name: 'Gallery',
      link: '/irscorner/gallery',
      icon: 'camera',
    },
    {
      name: 'Photography',
      link: '/irscorner/photography',
      icon: 'camera_roll',
    },
    {
      name: 'Hobbies',
      link: '/irscorner/hobbies',
      icon: 'palette',
    },
  ],
  publications: [
    {
      name: 'General',
      link: '/publications',
      icon: 'check_circle_outline',
    },
    {
      name: 'Blogs',
      link: '/publications/blogs',
      icon: 'pages',
    },
    {
      name: 'Research Papers',
      link: '/publications/researchpapers',
      icon: 'emoji_objects',
    },
    {
      name: 'Articles',
      link: '/publications/articles',
      icon: 'layers',
    },
    {
      name: 'Books & Novels',
      link: '/publications/booksandnovels',
      icon: 'library_books',
    },
  ],
  citizencorner: [
    {
      name: 'General',
      link: '/citizencorner',
      icon: 'check_circle_outline',
    },
    {
      name: 'CRM Activities',
      link: '/citizencorner/crmactivity',
      icon: 'emoji_nature',
    },
    {
      name: 'Award and Recognition',
      link: '/citizencorner/awardandrecognition',
      icon: 'emoji_events',
    },
  ],
  login: [
    {
      name: 'IRSA Email',
      link: '/',
      icon: 'mail',
    },
    {
      name: 'Official email system',
      link: '/',
      icon: 'email',
    },
  ],
  mygov: [
    {
      name: 'Ministry of Finance',
      link: '/',
      icon: 'account_balance',
    },
    {
      name: 'CBDT',
      link: '/',
      icon: 'business',
    },
    {
      name: 'IRS Officers Online',
      link: '/',
      icon: 'public',
    },
  ],
}

export default data
